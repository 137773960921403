import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phone: yup.number().typeError('Phone must be a number type').required('Phone Number is required').positive().integer(),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    society: yup.string().required('Society is required'),
    checkbox: yup.boolean().oneOf([true], 'Checkbox must be checked'),
});

function BookADemoForm() {
    const [emailSent, setEmailSent] = useState(false);
    const handleSendEmail = async () => {
        try {
            const response = await fetch('/api/sendEmail.php', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setEmailSent(true);
            } else {
                console.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            city: '',
            state: '',
            society: '',
            checkbox: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values.checkbox) {
                try {
                    const response = await fetch('/api/sendEmail.php', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values),
                    });

                    if (response.ok) {
                        console.log('Email sent successfully');
                    } else {
                        console.error('Failed to send email');
                    }
                } catch (error) {
                    console.error('Error sending email:', error);
                }
            } else {
                console.log('Checkbox not checked. Email not sent.');
            }
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="container mt-4">
                <div className="row book-demo-form">
                    <div className="col-sm-12">
                        <p>
                            <span className="form-control-wrap">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Name*"
                                    className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <p className="invalid-feedback">{formik.errors.name}</p>
                                )}
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-12">
                        <p>
                            <span className="form-control-wrap">
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Email Address*"
                                    className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <p className="invalid-feedback">{formik.errors.email}</p>
                                )}
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-12">
                        <p>
                            <span className="form-control-wrap">
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Phone Number*"
                                    className={`form-control ${formik.touched.phone && formik.errors.phone ? 'is-invalid' : ''}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <p className="invalid-feedback">{formik.errors.phone}</p>
                                )}
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <p>
                            <span className="form-control-wrap">
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="City*"
                                    className={`form-control ${formik.touched.city && formik.errors.city ? 'is-invalid' : ''}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                />
                                {formik.touched.city && formik.errors.city && (
                                    <p className="invalid-feedback">{formik.errors.city}</p>
                                )}
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <p>
                            <span className="form-control-wrap">
                                <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    placeholder="State*"
                                    className={`form-control ${formik.touched.state && formik.errors.state ? 'is-invalid' : ''}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.state}
                                />
                                {formik.touched.state && formik.errors.state && (
                                    <p className="invalid-feedback">{formik.errors.state}</p>
                                )}
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-12">
                        <p>
                            <span className="form-control-wrap">
                                <input
                                    type="text"
                                    id="society"
                                    name="society"
                                    placeholder="Society*"
                                    className={`form-control ${formik.touched.society && formik.errors.society ? 'is-invalid' : ''}`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.society}
                                />
                                {formik.touched.society && formik.errors.society && (
                                    <p className="invalid-feedback">{formik.errors.society}</p>
                                )}
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-12">
                        <div className="mt-3 mb-5 form-check">
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="checkbox"
                                className={`form-check-input ${formik.touched.checkbox && formik.errors.checkbox ? 'is-invalid' : ''}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.checkbox}
                            />
                            <label htmlFor="checkbox" className="form-check-label">
                                I agree to the terms and conditions
                            </label>
                            {formik.touched.checkbox && formik.errors.checkbox && (
                                <div className="invalid-feedback">{formik.errors.checkbox}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-12 mb-0">
                        <p>
                            <input
                                className="form-control-btn submit has-spinner"
                                type="submit"
                                value="Submit Demo Request" />
                        </p>
                    </div>
                </div>
            </form>
            {/* <div>
                {emailSent ? (
                    <p>Email sent successfully!</p>
                ) : (
                    <button onClick={handleSendEmail}>Send Email</button>
                )}
            </div> */}
        </>
    )
}

export default BookADemoForm