import React, { useState, useEffect } from 'react';
import VisitorForm from '../components/VisitorForm/VisitorForm';
import PageLayout from '../common/PageLayout';

import { saveToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from "../utils/localStorage"

function Visitor() {
    //URL Validating From Cache
    const [urlId, setUrlId] = useState(null)
    const [cached, setCached] = useState()
    const [currentURLID, setCurrentURLID] = useState(null)
    //URL Validating From Cache End

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const idParam = urlParams.get('id');
        setUrlId(idParam)
        setCurrentURLID(idParam)

        //Cached The ID On First Load To Verify Between Current and Stored Value
        const storedId = localStorage.getItem('Society_Id');
        if (!storedId) {
            localStorage.setItem('Society_Id', idParam);
            setCached(urlId);
        } else {
            setCached(storedId)
        }

    }, [cached]);

    const mId = `${cached}`;


    return (
        <>
            <PageLayout>
                {cached === currentURLID ? (
                    <>
                        <VisitorForm maskId={mId} />
                    </>
                ) : (
                    <>
                        <div id="notfound">
                            <div className="notfound">
                                <div className="notfound-404">
                                    <h3>Oops! Page not found</h3>
                                    <h1><span>4</span><span>0</span><span>4</span></h1>
                                </div>
                                <h2>we are sorry, but the page you requested was not found</h2>
                            </div>
                        </div>
                    </>
                )}
            </PageLayout>
        </>
    )
}

export default Visitor