import React from 'react'
import PageLayout from '../common/PageLayout'
import UnderConstruction from '../pages/UnderConstruction'
function TermCondition() {
    return (
        <>
            <PageLayout>
                <UnderConstruction />
            </PageLayout>
        </>
    )
}

export default TermCondition