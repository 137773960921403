import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form"
import axios from "axios"

import uploadImg from '../../assets/images/upload.jpg'
import OtpInput from "../OTP/OTPInput";
import Thanks from "../Thanks/Thanks"

const domain = process.env.REACT_APP_API_BASE_URL;

function VisitorForm({ maskId }) {
    const { register, handleSubmit, watch, formState: { errors }, } = useForm()
    const [sMUID, setSMUID] = useState(maskId)
    const [image, setImage] = useState("")
    const [base64String, setBase64String] = useState('');
    const fileInputRef = useRef(null);

    const base64ImgModified = [
        `data:image/webp;base64,${base64String}`,
        `data:image/webp;base64,/${base64String}`,
        `data:image/jpeg;base64,${base64String}`,
        `data:image/jpeg;base64,/${base64String}`,
        `data:image/png;base64,${base64String}`,
        `data:image/png;base64,/${base64String}`
    ];

    //Send QR OTP
    const [remainingTime, setRemainingTime] = useState(0);
    const [showOtpInput, setShowOtpInput] = useState(false)
    const [refId, setRefId] = useState('')
    const [hCode, setHcode] = useState('')
    const [otpTimer, setOTPtimer] = useState('')
    const [otpVerified, setOTPVerified] = useState(false);

    const [visitorTypeData, setVisitorTypeData] = useState([])
    const [getFlatData, setFlatData] = useState([])

    const maskingGuID = `${sMUID}`;

    //Base64 To String
    const imgData = base64String;

    //Storing Visitor Data On Fly
    const [mGuID, setMGuID] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [referenceId, setReferenceId] = useState('')
    const [purposeToMeet, setPurposeToMeet] = useState('')
    const [vechicalNumber, setVechicalNumber] = useState('')
    const [visitingFlatId, setVisitingFlatId] = useState('')
    const [visitorCategoriesId, setVisitorCategoriesId] = useState('')

    const postDataAfterVerification = {
        maskingGuID: `${mGuID}`,
        name: `${name}`,
        address: `${address}`,
        phoneNumber: `${phoneNumber}`,
        referenceId: `${refId}`,
        image: `${base64String}`,
        purposeToMeet: `${purposeToMeet}`,
        vechicalNumber: `${vechicalNumber}`,
        visitingFlatId: `${visitingFlatId}`,
        visitorCategoriesId: `${visitorCategoriesId}`
    }

    useEffect(() => {
        fetchVisitorType()
        fetchFlat(maskingGuID)
        //OTP Timer Validation
        let timerId;
        const startTimer = () => {
            timerId = setTimeout(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
        };

        const stopTimer = () => {
            clearTimeout(timerId);
        };

        if (remainingTime > 0) {
            startTimer();
        }
        // Re-Direction To Other Page 
        if (otpVerified) {
            const redirectTimeout = setTimeout(() => {
                window.location.href = '/'
            }, 10000);
        }

        return () => {
            stopTimer();
        };

    }, [otpVerified, remainingTime])

    const handleCheckIn = (data) => {
        console.log(base64String)
        //set Visitor Data
        const vData = {
            maskingGuID: `${maskingGuID}`,
            name: data.name,
            address: data.address,
            mobile: data.phoneNumber,
            referenceId: `${refId}`,
            image: `${base64String}`,
            purposeToMeet: data.purposeToMeet,
            vechicalNumber: data.vechicalNumber,
            visitingFlatId: data.visitingFlatId,
            visitorCategoriesId: data.visitorCategoriesId
        }
        setMGuID(maskingGuID)
        setName(vData.name)
        setAddress(vData.address)
        setPhoneNumber(vData.phoneNumber)
        setReferenceId(vData.referenceId)
        setImage(vData.image)
        setPurposeToMeet(vData.purposeToMeet)
        setVechicalNumber(vData.vechicalNumber)
        setVisitingFlatId(vData.visitingFlatId)
        setVisitorCategoriesId(vData.visitorCategoriesId)

        // Call OTP Post API
        const postData = {
            mobile: data.phoneNumber,
        }
        setPhoneNumber(postData.mobile);
        const apiQROTP = `${domain}/Account/SendQROTP`
        axios.post(apiQROTP, postData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            setRefId(response.data.result.referenceId)
            setHcode(response.data.result.userHashCode)
            setOTPtimer(response.data.result.otpValidInSeconds)
            setRemainingTime(response.data.result.otpValidInSeconds)

        }).catch(error => {
            console.error('Error fetching data:', error);
        });
        setShowOtpInput(true);

    }
    const handleImageChange = () => {
        const selectedImage = fileInputRef.current.files[0];
        if (selectedImage) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                {
                    base64ImgModified.map((str, index) => {
                        const cleanedBase64String = cleanBase64String2(reader.result);
                        return (
                            setBase64String(cleanedBase64String),
                            console.log(cleanedBase64String)
                        )
                    })
                }
            };
            reader.readAsDataURL(selectedImage);
        }
    }

    const handleUploadClick = () => {
        fileInputRef.current.click();
    }

    const handleResend = () => {
        setRemainingTime(remainingTime);
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `Time left: ${formattedMinutes}:${formattedSeconds}`;
    }

    //API Handler
    const fetchVisitorType = async () => {
        try {
            const response = await fetch(`${domain}/AppMaster/VisitorTypes`);
            const jsonData = await response.json();
            setVisitorTypeData(jsonData.result)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const fetchFlat = async (id) => {
        try {
            if (id) {
                const response = await fetch(`${domain}/AppMaster/GetFlat?societyMaskingId=${id}`);
                const jsonData = await response.json();
                setFlatData(jsonData.result)
            } else {
                console.log('Flat id not found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const sendQROTP = async (data, e) => {
        // e.preventDefault();
        const postData = {
            mobile: data.phoneNumber,
        }
        console.log(postData)
        setPhoneNumber(postData.mobile);
        // Call OTP Post API
        const apiQROTP = "http://ssm.techstern.com/api/Account/SendQROTP"
        axios.post(apiQROTP, postData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response)
            setRefId(response.data.result.referenceId)
            setHcode(response.data.result.userHashCode)
            setOTPtimer(response.data.result.otpValidInSeconds)
            setRemainingTime(response.data.result.otpValidInSeconds)

        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    const onOtpSubmit = (otp) => {
        const verifyData = {
            referenceId: refId,
            hashCode: hCode,
            otp: otp,
        }
        // Call OTP Post API
        const apiVerifyQROTP = `${domain}/Account/VerifyQROTP`
        axios.post(apiVerifyQROTP, verifyData, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log('Response:', response);
            if (response.data.isSuccess == true) {
                console.log(postDataAfterVerification)
                const apiAddVisitor = `${domain}/AppMaster/AddVisitor`
                axios.post(apiAddVisitor, JSON.stringify(postDataAfterVerification), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log('Response:', response);
                }).catch(error => {
                    console.error('Error fetching data:', error);
                });
                setOTPVerified(true)
            }

            if (response.data.isSuccess == false) {
                toast.error(`${response.data.message}`);
            }

        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    // Function to clean the base64 string
    const cleanBase64String = (str) => {
        return str.replace(/^data:image\/(?:webp|png|jpeg);base64,\/?/, '');
    }

    // Function to extract the MIME type from the base64 string
    const getMimeType = (str) => {
        const match = str.match(/^data:(.*?);base64,/);
        return match ? match[1] : '';
    };
    // Function to clean the base64 string
    const cleanBase64String2 = (str) => {
        return str.replace(/^.*?base64,/, '');
    }

    return (
        <>
            {!showOtpInput ? (
                <>
                    <div className='visitor-feed'>
                        <div className='container'>
                            <form onSubmit={handleSubmit(handleCheckIn)}>
                                <div className="row">
                                    <div className='position-relative col-md-12 mb-3 p-0'>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleImageChange}
                                        />
                                        <span className="form-control-wrap base64Input">
                                            <input
                                                type="text"
                                                name="image"
                                                readOnly
                                                value={base64String}
                                                {...register("image", { required: false })}
                                            />
                                        </span>

                                        <figure
                                            className={`uploadImage ${errors.image ? 'is-invalid' : ''}`}
                                            onClick={handleUploadClick}>
                                            {image ? (
                                                <img src={image} alt="Preview" />
                                            ) : (
                                                <img src={uploadImg} />
                                            )}
                                        </figure>
                                        <label className='d-block text-center py-4'>Upload Image</label>
                                    </div>

                                    <div className="col-md-12 mb-3 p-0">
                                        <label htmlFor="visitorCategoriesId">Visitor Type <span>*</span></label>
                                        <select name="visitorCategoriesId"
                                            {...register("visitorCategoriesId", { required: true })}
                                            className={`form-control m-0 ${errors.visitorCategoriesId ? 'is-invalid' : ''}`}>
                                            <option value="">Select visitor type</option>
                                            {visitorTypeData.map(option => (
                                                <option id={option.id} key={option.id} value={option.id}>{option.name}</option>
                                            ))}
                                        </select>

                                        <div className="invalid-feedback">
                                            {errors.visitorCategoriesId && <span>Visitor type is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3 p-0">
                                        <label htmlFor="flat">Flat <span>*</span></label>
                                        <select name="visitingFlatId"
                                            {...register("visitingFlatId", { required: true })}
                                            className={`form-control m-0 ${errors.visitingFlatId ? 'is-invalid' : ''}`}>
                                            <option value="">Select flat</option>
                                            {getFlatData.map(option => (
                                                <option id={option.flatNumber} key={option.id} value={option.id}>{option.blockName}-{option.flatNumber}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {errors.visitingFlatId && <span>Flat number is required</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3 p-0">
                                        <label htmlFor="name">Visitor Name <span>*</span></label>
                                        <span className="form-control-wrap">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Visitor name*"
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                {...register("name", { required: true, pattern: /^[a-zA-Z\s]{2,}$/ })}
                                            />
                                            <div className="invalid-feedback">
                                                {errors.name ? (
                                                    <span>
                                                        {errors?.name?.type === "pattern" ? (
                                                            "Visitor name must contain at least 2 characters and numbers or symbols not accepted"
                                                        ) : (
                                                            "Visitor name is required"
                                                        )}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </span>
                                    </div>

                                    <div className="col-md-12 mb-3 p-0">
                                        <label htmlFor="address">Visitor Address <span>*</span></label>
                                        <span className="form-control-wrap">
                                            <textarea
                                                type="text"
                                                id="address"
                                                name="address"
                                                placeholder="Visitor address*"
                                                className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                                {...register("address", { required: true })}
                                            ></textarea>
                                            <div className="invalid-feedback">
                                                {errors.address && <span>Visitor address is required</span>}
                                            </div>
                                        </span>
                                    </div>

                                    <div className="col-md-12 mb-3 p-0">
                                        <label htmlFor="vechicalNumber">Vehicle No:</label>
                                        <span className="form-control-wrap">
                                            <input
                                                type="text"
                                                id="vechicalNumber"
                                                name="vechicalNumber"
                                                placeholder="Enter Vehicle Number : JH 01 2020"
                                                className={`form-control ${errors.vechicalNumber ? 'is-invalid' : ''}`}
                                                {...register("vechicalNumber", { required: false, pattern: /^[A-Z]{2}\s[0-9]{2}\s[0-9]{4}$/ })}
                                            />
                                            <div className="invalid-feedback">

                                                {errors.vechicalNumber ? (
                                                    <span>
                                                        {errors?.vechicalNumber?.type === "pattern" ? (
                                                            "Alphabetical & Number characters only"
                                                        ) : (
                                                            "Vehicle number is required"
                                                        )}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </span>
                                    </div>

                                    <div className="col-md-12 mb-3 p-0">
                                        <label htmlFor="phoneNumber">Mobile Number <span>*</span></label>
                                        <span className="form-control-wrap">
                                            <input
                                                type="text"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                placeholder="Mobile Number*"
                                                className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                                {...register("phoneNumber", { required: true, pattern: /^(?:\d{10}|\d{12})$/ })}
                                            />
                                            <div className="invalid-feedback">
                                                {errors.phoneNumber ? (
                                                    <span>
                                                        {errors?.phoneNumber?.type === "pattern" ? (
                                                            "Mobile number is not in valid requirement."
                                                        ) : (
                                                            "Mobile number is required"
                                                        )}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </span>
                                    </div>

                                    <div className="col-md-12 mb-3 p-0">
                                        <label htmlFor="purposeToMeet">Purpose of Visit <span>*</span></label>
                                        <span className="form-control-wrap">
                                            <textarea
                                                type="text"
                                                id="purposeToMeet"
                                                name="purposeToMeet"
                                                placeholder="Purpose of visit*"
                                                className={`form-control ${errors.purposeToMeet ? 'is-invalid' : ''}`}
                                                {...register("purposeToMeet", { required: true })}
                                            ></textarea>
                                            <div className="invalid-feedback">
                                                {errors.purposeToMeet && <span>Purpose of visit is required</span>}
                                            </div>
                                        </span>
                                    </div>

                                    <div className="col-md-12 mt-3 mb-3 p-0">
                                        <button
                                            type='submit'
                                            className="w-100 form-control-btn submit has-spinner">
                                            Check In
                                        </button>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </>
            ) : (
                <>

                    {otpVerified ? (
                        <>
                            <Thanks />
                        </>) : (
                        <>
                            <div className='otp-verification-wrap'>
                                <div className='otp-ui-controller'>
                                    <div>
                                        <label className='d-block text-center m-0'>User Verification</label>
                                        <p className='text-center'>Enter OTP Sent to <strong>{phoneNumber}</strong></p>
                                        <OtpInput length={4} onOtpSubmit={onOtpSubmit} />
                                    </div>
                                    <div>
                                        <div className='d-flex flex-column justify-content-center my-3'>
                                            {!otpVerified ? (
                                                <>
                                                    <p className='text-center' id="countdown">{formatTime(remainingTime)}</p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className='text-center text-success'><strong>OTP Verified</strong></p>
                                                </>
                                            )}

                                            {/* {remainingTime === 0 && (
                                                <p className='text-center'>
                                                    Don't receive the code?{' '}
                                                    <span
                                                        className="font-weight-bold text-color cursor"
                                                        onClick={handleResend}>
                                                        Resend
                                                    </span>
                                                </p>
                                            )} */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}
                </>
            )
            }

            <ToastContainer />
        </>
    )
}

export default VisitorForm