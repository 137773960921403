import React from 'react'
import HeaderImage from '../assets/images/home-page-img-01.jpg'


function HeaderBanner() {
    return (
        <>
            <div className='header-banner'>
                <div className='header-bg'>
                    <img src={HeaderImage}/>
                </div>
                <div className="header-title-section section-title text-center text-white">
                    <span className="title-tagline text-white">About Society Prime</span>
                    <h2 className="main-title text-white">Welcome to Society Prime App</h2>
                    <p>Connect with your community like never before! Our society app brings neighbors, friends and local events together in one place.</p>
                </div>
            </div>
        </>
    )
}

export default HeaderBanner