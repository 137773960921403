import React from 'react'
import { Link } from 'react-router-dom'
import SocietyPrimeLogo from '../assets/images/SocietyPrime-01.png'

function Footer() {
  return (
    <>
      <footer className="site-footer">
        <div className="footer-main">
          <div className="container">
            <div className="row justify-content-between gap-4 m-0">
              <div className="col-lg-3 col-md-6 widget-column">
                <div id="media_image-2" className="widget widget_media_image text-left">
                  <img loading="lazy" width="170" height="46" src={SocietyPrimeLogo} className="image wp-image-10278  attachment-170x46 size-170x46" alt="" />
                </div>
                <div id="text-2" className="widget widget_text text-left">
                  <div className="textwidget">
                    <p>Connect with your community like never before! Our society app brings neighbours, friends and local events together in one place.</p>
                  </div>
                </div>
                {/* <div id="social_media-2" className="widget widget_social_media text-left">
                  <ul className="social-info style-flat color-dark shape-rounded">
                    <li> <a href="https://www.facebook.com/"><i className="fab fa-facebook-f" aria-hidden="true"></i></a> </li>
                    <li> <a href="https://twitter.com/"><i className="fab fa-twitter" aria-hidden="true"></i></a> </li>
                    <li> <a href="https://www.instagram.com/"><i className="fab fa-instagram" aria-hidden="true"></i></a> </li>
                    <li> <a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a> </li>
                    <li> <a href="https://dribbble.com/"><i className="fab fa-dribbble" aria-hidden="true"></i></a> </li>
                  </ul>
                </div> */}
              </div>

              <div className="col-lg-2 col-md-6 widget-column">
                <div id="nav_menu-2" className="widget widget_nav_menu text-left">
                  <h4 className="widget-title">Important Links</h4>
                  <div className="menu-useful-link-container">
                    <ul id="menu-useful-link" className="menu">
                      <li> <Link to="/privacy-policy">Privacy Policy</Link> </li>
                      <li> <Link to="/term-condition">Terms & Conditions</Link> </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-6 widget-column">
                <div id="nav_menu-3" className="widget widget_nav_menu text-left">
                  <h4 className="widget-title ">Service Menu</h4>
                  <div className="menu-useful-link-container">
                    <ul id="menu-useful-link-1" className="menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-11 current_page_item menu-item-9763"><a href="https://webvite.powersquall.com/" aria-current="page">Home</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9765"><a href="https://webvite.powersquall.com/about-us/">About Us</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9764"><a href="https://webvite.powersquall.com/blog/">Blog</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9766"><a href="https://webvite.powersquall.com/faq-page/">FAQ Page</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9767"><a href="https://webvite.powersquall.com/our-services/">Our Services</a></li>
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-3 col-md-6 widget-column">
                <div id="contact-2" className="widget widget_contact text-left">
                  <h4 className="widget-title ">Contact Information</h4>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <i className="fas fa-map-marker-alt"></i>
                        <span>201, 2nd Floor, MR Tower, Line Tank Road, Ranchi (JH) - 834001</span>
                      </li>
                      <li>
                        <a href="mailto:info@societyprime.com">
                          <i className="fas fa-envelope"></i>
                          <span>info@societyprime.com</span>
                        </a>
                      </li>
                      <li>
                        <i className="fas fa-headset"></i>
                        <span>0651 796 1217</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-info">
          <div className="container">
            <div className="copyright-info">
              <div className="row m-0">
                <div className="col-sm-6 text-start">
                  Copyright © 2024-25 All Rights Reserved.
                </div>
                <div className="col-sm-6 text-end">
                  {/* <div className="important-links">
                    <ul>
                      <li> <Link to="/privacy-policy">Privacy Policy</Link> </li>
                      <li> <Link to="/term-condition">Terms & Conditions</Link> </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer