import React, { useState } from 'react'
import MobileMenu2 from '../common/MobileMenu2';
import DesktopMenu from '../common/DesktopMenu';

function Header() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };    
   
    return (
        <>

            <DesktopMenu setMobileMenuOpen={setMobileMenuOpen} />

            {isMobileMenuOpen && <MobileMenu2 />}            
            

        </>
    )
}

export default Header