import React, { useState } from 'react'
import { Link } from "react-router-dom";

import 'rsuite/Sidenav/styles/index.css';
import 'rsuite/Nav/styles/index.css';
import { Sidenav, Nav } from 'rsuite';

const styles = {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgb(34 39 51 / 0%)",
    overflowY: "auto",
    position: "absolute",
    backdropFilter: "blur(2px)",
    top: "70px",
    zIndex:"99"
}
const CustomSidenav = ({ appearance, openKeys, expanded, onOpenChange, onExpand, ...navProps }) => {
    return (
        <div style={styles}>
            <Sidenav
                appearance={appearance}
                expanded={expanded}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
            >
                <Sidenav.Body>
                    <Nav {...navProps}>
                        <Nav.Item as={'div'} eventKey="1">
                            <Link to="/">Home</Link>
                        </Nav.Item>
                        <Nav.Item as={'div'} eventKey="2">
                            <Link to="/about-us">About Us</Link>                            
                        </Nav.Item>
                        <Nav.Item as={'div'} eventKey="3">
                            <Link to="/contact-us">Contact Us</Link>                            
                        </Nav.Item>
                        <Nav.Item as={'div'} eventKey="4">
                            <Link to="/book-a-demo">Book a Demo </Link>                          
                        </Nav.Item>
                    </Nav>
                </Sidenav.Body>
                {/* <Sidenav.Toggle onToggle={onExpand} /> */}
            </Sidenav>
        </div>
    );
};

function MobileMenu(props) {
    const [activeKey, setActiveKey] = React.useState('1');
    const [openKeys, setOpenKeys] = React.useState(['3', '4']);
    const [expanded, setExpand] = React.useState(true);
    
    return (
        <>
            <CustomSidenav
                activeKey={activeKey}
                openKeys={openKeys}
                onSelect={setActiveKey}
                onOpenChange={setOpenKeys}
                expanded={expanded}
                onExpand={setExpand}
            />

        </>

    )
}

export default MobileMenu