import React, { useState } from 'react'
import PageLayout from '../common/PageLayout'
import UnderConstruction from './UnderConstruction'
import GlobalDialog from '../components/GlobalDialog '
import BookADemoForm from '../components/BookADemoForm'

function BookADemo() {

  return (
    <>
      <PageLayout>
        {/* <UnderConstruction /> */}
        <BookADemoForm />
      </PageLayout>
    </>
  )
}

export default BookADemo