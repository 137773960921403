import React, { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import SocietyPrimeLogo from '../assets/images/SocietyPrime-01.png'
import BookADemoForm from '../components/BookADemoForm';
import GlobalDialog from '../components/GlobalDialog ';

function DesktopMenu(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 921);
    const buttonRef = useRef();
    const [isActive, setActive] = useState(false);

    const [showDialog, setShowDialog] = useState(false);
    const handleShow = () => setShowDialog(true);
    const handleClose = () => setShowDialog(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 921);
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            // buttonRef.current.click();
        };
    }, [])

    // useEffect(() => {
    //     buttonRef.current.click();
    // }, []);

    const toggleClass = () => {
        setActive(!isActive);
        props.setMobileMenuOpen(isActive);

    }

    const BookADemo = () => {
        return (
            <>
                <BookADemoForm />
            </>
        );
    };
    return (
        <>
            <header id="site-header" className="site-header header-fancy sticky-on header-color-default submenu-dark topbar-mobile-off">
                <div className="header-main">
                    <div className="header-inner">
                        <div className="header-logo">
                            <Link className="site-logo" to="/">
                                <img src={SocietyPrimeLogo} className="img-fluid" alt="Society Prime" />
                            </Link>
                            <Link className="sticky-site-logo" to="/">
                                <img src={SocietyPrimeLogo} className="img-fluid" alt="Society Prime" />
                            </Link>

                            {isMobile && (
                                <Link ref={buttonRef} className={isActive ? 'sidebar-menu sidebar-active' : 'sidebar-menu'} onClick={toggleClass}>
                                    <i className='fa fa-bars'></i>
                                </Link>
                            )}
                        </div>

                        <div className="main-navigation">
                            <div id="primary-menu" className="menu-primary-menu-container">
                                <ul id="menu" className="nav navbar-nav">

                                    <li className="menu-item">
                                        <Link to="/">Home</Link>
                                    </li>

                                    <li className="menu-item">
                                        <Link to="/about-us">About Us</Link>
                                    </li>

                                    <li className="menu-item">
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>

                                    <li className="menu-item">
                                        <Link to="/visitor?id=2aeb7913-24bf-47d3-8416-38e1298ca864">Visitor</Link>
                                    </li>

                                    <li className="menu-item">
                                        <Link onClick={handleShow}>Book A Demo</Link>
                                    </li>


                                </ul>
                            </div>
                        </div>

                        <div className="feature-box info-call icon-left mr-3">
                            <div className="feature-icon"><i className="ti ti-headphone-alt"></i></div>
                            <div className="feature-info">
                                <span className="phone-label mb-0">Contact Number</span>
                                <h6 className="phone-number mb-0"><a href="tel:+0123456789">0651 796 1217</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <GlobalDialog
                showDialog={showDialog}
                handleClose={handleClose}
                dialogTitle="Book a Demo"
                dialogContent={<BookADemo />}
                dialogSubmitButton="Submit Demo Request" />

        </>
    )
}

export default DesktopMenu