import React from 'react'

function Thanks() {
    return (
        <>
            <div className="thanks-page text-center">
                <div className="thanks-box">
                    <h1 className="text-center text-success">Thank You!</h1>
                    <p className="text-center"><strong>Please wait...</strong> for further instructions.</p>
                </div>
            </div>
        </>
    )
}

export default Thanks