import React from 'react'
import PageLayout from '../common/PageLayout'
import AboutUsAnimText from '../assets/images/about-us-dark.png'
import MaintenanceManagement from '../assets/images/maintenance-management.png'
import AboutUsImg01 from '../assets/images/about-img-society.jpg'
import SocietyPrimeIconImg from '../assets/images/sp-512x512-03.png'
import wspimg01 from '../assets/images/uploads/blog-9.jpg'
import wspimg02 from '../assets/images/uploads/blog-4.jpg'
import wspimg03 from '../assets/images/uploads/blog-5.jpg'
import wspimg04 from '../assets/images/uploads/blog-2.jpg'

function AboutUs() {
  return (
    <>
      <PageLayout>
        <div className='container-fluid'>
          {/* ABOUT US SECTION */}
          <section className='about-section'>
            <div className='row m-0'>
              <div className='col-md-5'>
                <div className="image-parallax-wrapper text-infinite-rotate">
                  <div className="parallax-image">
                    <img src={AboutUsAnimText} alt="Parallax Image" className="img-parallax" />
                  </div>
                </div>
                <figure className="image-wrap">
                  <img
                    width="695" height="637"
                    src={AboutUsImg01}
                    className="attachment-full size-full wp-image-11466"
                    alt="" />

                </figure>
              </div>
              <div className='col-md-7'>
                <div className="section-title text-left">
                  <span className="title-tagline">About</span>
                  <h2 className="main-title">Society Prime</h2>
                </div>
                <p>
                  Society prime is an app created to manage security and community living in housing societies. The app was launched in 2023.
                </p>
                <p>
                  Society prime is a versatile mobile app designed to enhance community engagement with features like event Scheduling, group messaging and resources sharing, it fosters seamless communication within societies. Users can stay informed about neighborhood updates, participate in discussion, and streamline community activities through this user-friendly platform.
                </p>
                <p>
                  The Society prime App also facilitates easy management of common amenities, allowing residents to book facilities, report issues, and access essential services.
                </p>

                <p>
                  <strong>
                    It’s a must have app for anyone staying in the apartment complex to come together as a community.
                  </strong>
                </p>

                {/* <div className="button-wrapper text-left">
                  <a href="#" className="btn-default default-theme btn-icon style-flat hover-slide-left">
                    <span className="webvite-button-inner">
                      <span className="webvite-button-text">Read More</span>
                    </span>
                  </a>
                </div> */}

              </div>
            </div>
          </section>

          {/* Why Society Prime */}
          <section className='why-society-prime'>
            <div className='row m-0'>
              <div className='col-md-6'>
                <div className="section-title text-left">
                  <span className="title-tagline">About</span>
                  <h2 className="main-title">Society Prime</h2>
                </div>
                <p>
                  Society prime app to transform apartment living into a seamless, connected, and well-managed experience for both residents and property management.
                </p>

                <div className="list color-default icon-default size-extra-small">
                  <figure>
                    <img src={SocietyPrimeIconImg} />
                  </figure>
                  <ul>
                    <li><i aria-hidden="true" className="fas fa-star"></i> <span>Facility Booking Made Easy: Streamline amenities and facility bookings, allowing residents to schedule and access shared spaces conveniently through the app.</span></li>
                    <li><i aria-hidden="true" className="fas fa-star"></i> <span>Maintenance Requests: Residents can report and track maintenance issues swiftly, ensuring a prompt response from management and efficient problem resolution.</span></li>
                    <li>
                      <i aria-hidden="true" className="fas fa-star"></i>
                      <span>Announcements and Updates: Keep everyone in the loop with important announcements, community updates, and event notifications, enhancing overall communication and transparency.</span>
                    </li>
                    <li>
                      <i aria-hidden="true" className="fas fa-star"></i>
                      <span>Secure Access Control: Implement secure access controls for entry points, enhancing the safety and security of residents with features like digital key access and visitor management.</span>
                    </li>
                  </ul>
                </div>

              </div>
              <div className='col-md-6'>
                <div className='wsp-list-container scrollbar-hide'>
                  <div className='wsp-list'>
                    <figure>
                      <img src={wspimg01} />
                    </figure>
                    <h4 className="title">Communication, Engaged And Empowered</h4>
                    <p>Choose Society prime as your community's online hub and elevate your loving experience, strengthen connections, simplify communication, and build a more engaged and empowered and community today.</p>
                  </div>

                  <div className='wsp-list'>
                    <figure>
                      <img src={wspimg02} />
                    </figure>
                    <h4 className="title">Society Chat And Inter Society Chat</h4>
                    <p>Connect effortlessly with your neighbors through an intuitive messaging system, ensuring everyone stays informed and engaged.</p>
                  </div>

                  <div className='wsp-list'>
                    <figure>
                      <img src={wspimg03} />
                    </figure>
                    <h4 className="title">Transparency of Association</h4>
                    <p>Brings transparency into the operation of the association and thus improves satisfaction of owners and residents.</p>
                  </div>

                  <div className='wsp-list'>
                    <figure>
                      <img src={wspimg04} />
                    </figure>
                    <h4 className="title">Society Business Marketplace</h4>
                    <p>Elevate your business by becoming part of society’s prime network. Engage directly with residents, advertise your services, and participate in local services and participate in events to enhance your brand presence.</p>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </PageLayout>
    </>
  )
}

export default AboutUs