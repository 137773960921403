import React, { useState } from 'react';
// import { Modal, Button } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function GlobalDialog({ showDialog, handleClose, dialogTitle, dialogContent,dialogSubmitButton }) {
    return (
        <Modal show={showDialog} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{dialogContent}</Modal.Body>
            {/* <Modal.Footer>
                <Button variant="primary">{dialogSubmitButton}</Button>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            </Modal.Footer> */}
        </Modal>
    )
}

export default GlobalDialog 