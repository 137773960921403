import React from 'react'
import { Link } from "react-router-dom";
import { ReactComponent as AppStore } from '../assets/images/app-store.svg';
import { ReactComponent as GooglePlay } from '../assets/images/google-play.svg';

function GetTheApp() {
  return (
    <>
      <div className="button-wrapper text-left">
        <Link to="/" className="btn-default default-theme btn-icon style-default hover-slide-left">
          <span className="webvite-button-inner">
            <span className="webvite-button-icon webvite-align-icon-left">
              <AppStore />
            </span>
            <span className="webvite-button-text"></span>
          </span>
        </Link>
        <Link to="/" className="btn-default default-theme btn-icon style-default hover-slide-left">
          <span className="webvite-button-inner">
            <span className="webvite-button-icon webvite-align-icon-left">
              <GooglePlay />
            </span>
            <span className="webvite-button-text"></span>
          </span>
        </Link>

      </div>

    </>
  )
}

export default GetTheApp