import React from 'react'
import PageLayout from '../common/PageLayout'

function PrivacyPolicy() {
    return (
        <>
            <PageLayout>
                <div class="privacy-policy-wrap">
                    <section className='pt-3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h4>Privacy Policy</h4>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><strong>Effective Date: 23rd April, 2024  </strong></p>
                                    <p>
                                        Welcome to Society Prime, a mobile application developed by Techstern Solutions ("we," "us," "our"). Society Prime is designed to streamline society management tasks, enhancing communication and operational efficiency within residential societies.
                                    </p>
                                    <p>
                                        This Privacy Policy describes how we collect, use, process, and share your information, including personal information, in conjunction with your access to and use of Society Prime. By using the app, you acknowledge that you have read and understood this privacy policy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><strong>Information We Collect </strong></p>
                                    <ol>
                                        <li><strong>Personal Information:</strong> We collect information you provide directly to us when you create an account, update your profile, interact with the app, or communicate with us. This information may include your name, email address, phone number, profile picture, and any other information you choose to provide.  </li>

                                        <li><strong>Usage Information:</strong> We automatically collect information about your interactions with our app, such as the pages or other content you view, your searches for listings, and other actions on the app. </li>

                                        <li><strong>Location Information:</strong> With your permission, we may collect precise location data from your mobile device to offer location-based features. </li>

                                        <li><strong>Device Information:</strong> We may collect information about the mobile device you use to access our app, including the hardware model, operating system and version, unique device identifiers, and mobile network information. </li>

                                        <li><strong>Information Collected by Cookies and Similar Technologies:</strong> We use various technologies to collect information, including cookies and web beacons. These technologies help us understand your activity on our app, improve your user experience, and tailor our interactions with you. </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><strong>How We Use Your Information </strong></p>
                                    <p>We use the information we collect to: </p>

                                    <ul>
                                        <li>Provide, maintain, and improve the Society Prime app; </li>
                                        <li>Communicate with you about products, services, offers, and provide customer service;  </li>
                                        <li>Process transactions and send you related information, including confirmations and invoices;  </li>
                                        <li>Personalize and improve the Services and provide content or features that match user profiles or interests.  </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><strong>Sharing Your Information  </strong></p>
                                    <ol>
                                        <li><strong>Compliance with Laws:</strong> We may disclose your information to a third party if <br /> (a) we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request, <br /> (b) to enforce our agreements, policies, and terms of service, <br /> (c) to protect the security or integrity of our services, <br /> (d) to protect the property, rights, and safety of Techstern Solutions, our users, or the public from harm or illegal activities, or <br /> (e) to respond to an emergency which we believe in the good faith requires us to disclose information to assist in preventing the death or serious bodily injury of any person.</li>
                                        <li><strong>Business Transfers: </strong>In the event that Society App or Techstern Solutions undergoes a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><strong>Your Rights </strong></p>
                                    <p>You have the right to access, update, and delete your information. You can accomplish these actions directly within your account settings or by contacting us at our support email to request access to, correct, or delete any personal information that you have provided to us.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><strong>Changes to the Privacy Policy </strong></p>
                                    <p>
                                        We may update this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with more prominent notice (such as adding a statement to our homepage or sending you a notification).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><strong>Contact Us </strong></p>
                                    <p>
                                        <span className='px-3'>If you have any questions about this Privacy Policy, please contact us at</span>
                                        <a href="mailto:info@techstern.com">info@techstern.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </PageLayout>
        </>
    )
}

export default PrivacyPolicy