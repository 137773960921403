import React from 'react'
import Header from './Header'
import Footer from './Footer'

function PageLayout({ children }) {
  return (
    <>
      <div id='page' className='site'>
        <Header />
        <div className='site-content-contain'>
          <div id='content' className='site-content'>
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default PageLayout