import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css'
import './assets/css/fonts/all.min.css'
import './assets/css/fonts/themify-icons.css'
import './assets/css/fonts/simple-line-icons.css'
import './assets/css/fonts/linearicons.css'
import './assets/css/fonts/ionicons.css'
import './assets/css/fonts/font-awesome.min.css'
import './assets/css/fonts/flaticon.css'
import './assets/css/override-style.css'


import { BrowserRouter, Route, Routes } from 'react-router-dom'

import NotFound from './pages/NotFound';
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import BookADemo from './pages/BookADemo'
import Visitor from './pages/Visitor';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermCondition from './pages/TermCondition';
import VerifyOTP from './pages/VerifyOTP';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/book-a-demo" element={<BookADemo />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/term-condition" element={<TermCondition/>} />
          <Route path="/visitor" element={<Visitor/>} />
          {/* <Route path="/verify-otp" element={<VerifyOTP/>} /> */}
          <Route path='*' Component={NotFound} />

        </Routes>
      </BrowserRouter>      
    </>
  );
}

export default App;
