import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import PageLayout from '../common/PageLayout'

const validationSchema = yup.object().shape({
  name: yup.string().min(2).required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.number().typeError('Phone must be a number type').required('Phone Number is required').positive().integer(),
  subject: yup.string().required('Subject is required'),
  message: yup.string().required('Message is required'),
  checkbox: yup.boolean().oneOf([true], 'Checkbox must be checked'),
});

function ContactUs() {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      checkbox: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.checkbox) {
        try {
          const response = await fetch('/api/contactUs.php', {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          });

          if (response.ok) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      } else {
        console.log('Checkbox not checked. Email not sent.');
      }
    },
  });

  return (
    <>
      <PageLayout>
        <section className='contact-page'>
          <div className='row m-0'>
            <div className='col-md-5'>
              <div className="section-title">
                <span className="title-tagline">Get in Touch</span>
                <h5 className="main-title">Contact Details</h5>
                <p className='mt-3 mb-3'>201, 2nd Floor, MR Tower, <br />Line Tank Road, Ranchi (JH) - 834001</p>
                <strong>0651 796 1217</strong>
              </div>
            </div>
            <div className='col-md-7'>
              <form onSubmit={formik.handleSubmit} className="container mt-4">
                <div className="row">
                  <div className="col-sm-6">
                    <p>
                      <span className="form-control-wrap">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name*"
                          className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <p className="invalid-feedback">{formik.errors.name}</p>
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p>
                      <span className="form-control-wrap">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Email Address*"
                          className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <p className="invalid-feedback">{formik.errors.email}</p>
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p>
                      <span className="form-control-wrap">
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number*"
                          className={`form-control ${formik.touched.phone && formik.errors.phone ? 'is-invalid' : ''}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <p className="invalid-feedback">{formik.errors.phone}</p>
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <p>
                      <span className="form-control-wrap">
                        <input
                          type="text"
                          id="subject"
                          name="subject"
                          placeholder="Subject*"
                          className={`form-control ${formik.touched.subject && formik.errors.subject ? 'is-invalid' : ''}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subject}
                        />
                        {formik.touched.subject && formik.errors.subject && (
                          <p className="invalid-feedback">{formik.errors.subject}</p>
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-12">
                    <p>
                      <span className="form-control-wrap">
                        <textarea
                          cols="30" rows="5"
                          id="message"
                          name="message"
                          placeholder="How can we help you? Feel free to get in touch!*"
                          className={`form-control ${formik.touched.message && formik.errors.message ? 'is-invalid' : ''}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}>
                        </textarea>
                        {formik.touched.message && formik.errors.message && (
                          <p className="invalid-feedback">{formik.errors.message}</p>
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-10">
                    <div className="mt-3 mb-5 form-check">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        className={`form-check-input ${formik.touched.checkbox && formik.errors.checkbox ? 'is-invalid' : ''}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.checkbox}
                      />
                      <label htmlFor="checkbox" className="form-check-label">
                        By clicking Send a message button, you agree to use our “Form” terms And consent cookie usage in browser..
                      </label>
                      {formik.touched.checkbox && formik.errors.checkbox && (
                        <div className="invalid-feedback">{formik.errors.checkbox}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 mb-0">
                    <p>
                      <input
                        className="form-control-btn submit has-spinner"
                        type="submit"
                        value="Send a Message" />
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </PageLayout>
    </>
  )
}

export default ContactUs