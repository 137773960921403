import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion'

import HeaderBanner from '../components/HeaderBanner'
import AboutUsAnimText from '../assets/images/about-us-dark.png'
import MaintenanceManagement from '../assets/images/maintenance-management.png'
import AboutUsImg01 from '../assets/images/about-img-society.jpg'
import FeaturesIcon01 from '../assets/images/uploads/phone-book.png'
import FeaturesIcon02 from '../assets/images/uploads/family.png'
import FeaturesIcon03 from '../assets/images/uploads/gardener.png'
import FeaturesIcon04 from '../assets/images/uploads/guest.png'
import FeaturesIcon05 from '../assets/images/uploads/pay.png'
import FeaturesIcon06 from '../assets/images/uploads/complain.png'
import SpecificFeatureImg01 from '../assets/images/uploads/SocietyPrime_Banner_3.png'
import SpecificFeatureImg02 from '../assets/images/uploads/SocietyPrime_Banner_2.png'
import SpecificFeatureImg03 from '../assets/images/uploads/society-prime-visitors-management.jpg'
import PageLayout from '../common/PageLayout'
import GetTheApp from '../components/GetTheApp';


const contentData = [
  {
    id: 1,
    title: 'Administration & Management',
    content: 'Digital registration of residents, staff, and visitors.',
    image: `${SpecificFeatureImg01}`
  },
  {
    id: 2,
    title: 'Connecting communities',
    content: 'Messaging private chat options for residents to communicate with members.',
    image: `${SpecificFeatureImg02}`
  },
  {
    id: 3,
    title: 'Visitor Management',
    content: 'Track visitors, notifications to flat owners, maintain visitor logs. Emergency Alerts send out immediate notifications in case of emergencies.',
    image: `${SpecificFeatureImg03}`
  },
  // Add more sections as needed
];

function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? contentData.length - 1 : prevIndex - 1
    );
  };

  const toggleAutoPlay = () => {
    setAutoPlay(!autoPlay);
  };

  useEffect(() => {
    let autoPlayInterval;

    if (autoPlay) {
      autoPlayInterval = setInterval(nextSlide, 5000); // Change autoplay interval as needed
    }

    return () => {
      clearInterval(autoPlayInterval);
    };
  }, [autoPlay, currentIndex]);

  return (
    <>
      <PageLayout>
        <HeaderBanner />
        {/* GET APP SECTION */}
        <section className='get-the-app-section'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6  d-flex justify-content-between'>
                {/* <div className="feature-box d-md-flex gap-4 text-white">
                  <div className="feature-icon">
                    <i aria-hidden="true" className="ti ti-headphone-alt"></i>
                  </div>
                  <div className="feature-info">
                    <h6 className="title">Call For More Info</h6>
                    <p>+123 696 745</p>
                  </div>
                </div> */}
                <div className="feature-box style-default icon-left ">
                  <div className="feature-icon text-center">
                    <i aria-hidden="true" className="ti ti-cloud-down"></i>
                  </div>
                  <div className="feature-info">
                    <p>Get the Society Prime App.</p>
                  </div>
                </div>
                <div className='vr bg-white'></div>
              </div>
              <div className='col-md-6 d-flex  align-items-center justify-content-end'>
                <GetTheApp />
                {/* <div className='d-flex justify-content-between'>
                  <h6 className='text-white w-50'>Let’s Request a Schedule For Free Consultation</h6>
                  <div className="button-wrapper text-left">
                    <a href="#" className="btn-default default-theme btn-icon style-border hover-slide-left">
                      <span className="webvite-button-inner">
                        <span className="webvite-button-text">View All</span>
                      </span>
                    </a>
                  </div>                  
                </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* ABOUT US SECTION */}
        <section className='about-section'>
          <div className='container-fluid'>
            <div className='row m-0'>
              <div className='col-md-5'>
                <div className="image-parallax-wrapper text-infinite-rotate">
                  <div className="parallax-image">
                    <img src={AboutUsAnimText} alt="Parallax Image" className="img-parallax" />
                  </div>
                </div>
                <figure className="image-wrap">
                  <img
                    width="695" height="637"
                    src={AboutUsImg01}
                    className="attachment-full size-full wp-image-11466"
                    alt="" />

                </figure>
              </div>
              <div className='col-md-7'>
                <div className="section-title text-left">
                  <span className="title-tagline">About</span>
                  <h2 className="main-title">Society Prime</h2>
                </div>
                <p>
                  <strong>Society Prime</strong>, is an innovative mobile application specifically tailored for enhancing the management and communal experience within housing societies. This dynamic app offers an array of features aimed at fostering robust community engagement and streamlined communication. With functionalities such as event scheduling, group messaging, and resource sharing, Society Prime enables residents to stay abreast of neighborhood developments, engage in meaningful discussions, and efficiently coordinate community activities.
                </p>
                <p>
                  Moreover, Society Prime simplifies the management of shared amenities, empowering residents to effortlessly book facilities, report maintenance issues, and access a range of essential services. As an indispensable tool, Society Prime is essential for residents in apartment complexes who seek to cultivate a cohesive and vibrant community atmosphere.
                </p>
                <div className="button-wrapper text-left">
                  <a href="#" className="btn-default default-theme btn-icon style-flat hover-slide-left">
                    <span className="webvite-button-inner">
                      <span className="webvite-button-text">Read More</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* POPULAR Features */}
        <section className='popular-feature'>
          <div className='container-fluid'>
            <div className='row row-1 m-0 cs-feature-card'>
              <div className='col-md-3'>
                <div className="section-title text-left">
                  <span className="title-tagline">POPULAR</span>
                  <h2 className="main-title">Features</h2>
                </div>
                <p className='text-white'>
                  Experience the next level of community living through our advanced society app. Effortlessly oversee visitor management, maintain detailed family profiles, utilize daily assistance services, and stay informed about society dues. Additionally, the app offers an integrated system to report and monitor complaints with ease.
                </p>
              </div>


              <div className='col-md-9 cs-feature-card'>
                <div className="feature-box style-default icon-top text-center">
                  <div className="feature-icon">
                    <img src={FeaturesIcon01} title="Society Management" alt="Society Management" loading="lazy" />
                  </div>
                  <div className="feature-info">
                    <h5 className="title">Society Management</h5>
                    <p>Comprehensive Directory of the Society</p>
                  </div>
                </div>
                <div className="feature-box style-default icon-top text-center">
                  <div className="feature-icon">
                    <img src={FeaturesIcon02} title="Family Details" alt="Family Details" loading="lazy" />
                  </div><div className="feature-info">
                    <h5 className="title">Family Details</h5>
                    <p>Add your family members details to connect each other.</p></div>
                </div>
                <div className="feature-box style-default icon-top text-center">
                  <div className="feature-icon">
                    <img src={FeaturesIcon02} title="Family Details" alt="Family Details" loading="lazy" />
                  </div><div className="feature-info">
                    <h5 className="title">Vehicle Management</h5>
                    <p>Manage your vehicle</p></div>
                </div>
              </div>

            </div>
            <div className='row m-0 mt-4 row-2'>
              <div className='col-md-3 cs-feature-card'>
                <div className="feature-box style-default icon-top text-center">
                  <div className="feature-icon">
                    <img src={FeaturesIcon03} title="Daily Help" alt="Daily Help" loading="lazy" />
                  </div>
                  <div className="feature-info">
                    <h5 className="title">Daily Help</h5>
                    <p>Get your house help details on the App.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 cs-feature-card'>
                <div className="feature-box style-default icon-top text-center">
                  <div className="feature-icon">
                    <img src={FeaturesIcon04} title="Add Visitors" alt="Add Visitors" loading="lazy" /></div>
                  <div className="feature-info">
                    <h5 className="title">Add Visitors</h5>
                    <p>Add and edit your Visitors with details and status.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 cs-feature-card'>
                <div className="feature-box style-default icon-top text-center">
                  <div className="feature-icon">
                    <img src={FeaturesIcon05} title="Society Maintenance" alt="Society Maintenance" loading="lazy" /></div>
                  <div className="feature-info">
                    <h5 className="title">Society Maintenance</h5>
                    <p>Pay society dues, deposits &amp; advances.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 cs-feature-card'>
                <div className="feature-box style-default icon-top text-center">
                  <div className="feature-icon">
                    <img src={FeaturesIcon06} title="Add Complaint" alt="Add Complaint" loading="lazy" /></div>
                  <div className="feature-info">
                    <h5 className="title">Add Complaint</h5>
                    <p>Send any complaint and message to society admin.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* Specific Feature */}
        <section className='specific-feature pt-5 pb-5'>
          <div className='container-fluid'>
            <div className='row m-0'>
              <div className='col-md-5'>
                <div className="section-title text-left">
                  <span className="title-tagline">SPECIFIC</span>
                  <h2 className="main-title pb-4">Features</h2>
                </div>
              </div>

              <div className='col-md-7 p-0 d-flex align-items-center justify-content-end'>
                <div className='action-button-wrap ps-services-slider d-flex justify-content-end'>
                  <div className="services-control-area">
                    <div className="services-swiper-pagination"></div>
                    <div className="swiper-navigation-arrow">
                      <div className="swiper-button-prev" onClick={prevSlide}>
                        <i className="ti-arrow-left"></i>
                      </div>
                      <div className="swiper-button-prev swiper-autoplay-btn" onClick={toggleAutoPlay}>
                        <i className={autoPlay ? 'ti-control-pause' : 'ti-control-play'}></i>
                      </div>
                      <div className="swiper-button-next" onClick={nextSlide}>
                        <i className="ti-arrow-right"></i>
                      </div>
                    </div>
                  </div>

                  {/* <button className="prev-btn" onClick={prevSlide}>Prev</button> */}
                  {/* <button className="next-btn" onClick={nextSlide}>Next</button> */}
                  {/* <button className="autoplay-btn" onClick={toggleAutoPlay}>{autoPlay ? 'Pause' : 'Play'}</button> */}
                </div>
              </div>

            </div>

            <div className='row m-0'>
              <div className='col-md-12 p-0'>
                <div className="slider-container ps-services-slider">
                  <div className='swiper-wrapper'>
                    {contentData.map((slide, index) => (
                      <div
                        key={slide.id}
                        className={`swiper-slide slide ${index === currentIndex ? 'active' : ''}`}>
                        <div className='fancy-box'>
                          <div className='fancy-box-inner'>
                            <div className='fancy-box-img'>
                              <div className='fancy-img'>
                                <img src={slide.image}
                                  title="SocietyPrime_Banner_3" alt="SocietyPrime_Banner_3" loading="lazy" />
                              </div>
                            </div>

                            <div className='fancy-box-info'>
                              <span className='fancy-bg-icon'>
                                <i aria-hidden="true" className="glyph-icon flaticon-search-engine-1"></i>
                              </span>
                              <div className='fancy-info-inner'>
                                <span className='fancy-box-icon'>
                                  <i aria-hidden="true" className="glyph-icon flaticon-search-engine-1"></i>
                                </span>
                                <label className="fancy-category"></label>
                                <h5 className="fancy-box-title">{slide.title}</h5>
                                <div className="fancy-box-content">{slide.content}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Maintenance Management */}
        <section className='maintenance-management'>
          <div className='container-fluid'>
            <div className='row m-0'>
              <div className='col-md-6'>
                <div className="section-title text-left">
                  <span className="title-tagline">IMPLEMENTING</span>
                  <h2 className="main-title pb-4">Maintenance Management</h2>
                </div>

                <div className='according-wrap pt-5 pb-5'>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Maintenance Bills Can Be Generated For Any Months.</Accordion.Header>
                      <Accordion.Body>
                        Define the Rates, and the System will calculate the maintenance charges under different heads and for different types of premises.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Fixed Amount basis</Accordion.Header>
                      <Accordion.Body>
                        Depending upon the policies, we add the maintenance charges fixed or /square ft basis.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>GST Compatible</Accordion.Header>
                      <Accordion.Body>
                        Charge interest with GST will be added as applicable.
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>
                </div>
              </div>
              <div className='col-md-6'>
                <figure>
                  <img src={MaintenanceManagement} />
                </figure>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>

    </>
  )
}

export default Home